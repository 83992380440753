<template>
  <v-app-bar
    app
    dense
    style="background-color: white"
    height="80"
    elevate-on-scroll
    class="px-0 header"
    :class="[scrolled ? 'elevation-4' : 'elevation-0']"
    ref="app-bar"
  >
    <ul class="d-flex ma-0 pa-0 header__left" style="list-style: none">
      <li class="nav-item d-none d-sm-flex align-items-center">
        <p
          @click="$router.push({ path: '/' })"
          class="nav-link grey--text text--darken-1"
        >
          {{ this.$t('HEADER.GO_TO_STORE') }}
        </p>
      </li>
      <li class="nav-item d-none d-sm-flex align-items-center">
        <p
          @click="$router.push({ path: '/contact-us' })"
          class="nav-link grey--text text--darken-1"
          >{{ this.$t('HEADER.CON_TACTS') }}</p
        >
      </li>
      <li
        v-if="$vuetify.breakpoint.smAndDown"
        style="flex: unset"
        class="pa-0 d-flex align-items-center cursor-pointer"
        @click="$emit('collapseDrawer')"
      >
        <v-icon>mdi-menu-open</v-icon>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="ml-auto ma-0 pa-0 d-flex align-center" style="list-style: none">
      <li class="lang__dropdown-btn position-relative">
        <!-- <div @click="isLangListDropedDown = !isLangListDropedDown">
          <span>{{ choosenLang }}</span>
          <v-icon size="15" color="rgb(185, 185, 185)">mdi-chevron-down</v-icon>
        </div> -->
        <v-select
          :items="languages"
          item-text="text"
          v-model="choosenLang"
          outlined
          dense
          hide-details
          solo
          append-icon=""
        ></v-select>
        <!-- <ul v-show="isLangListDropedDown" class="lang__dropdown-list">
          <li v-for="lang in languages" :key="lang.value" @click="choosenLang = lang.value">
            {{ lang.text }}
          </li>
        </ul> -->
      </li>
      <li class="dropdown">
        <v-menu offset-y content-class="menu-shadow">
          <template v-slot:activator="{ on, attrs }">
            <a
              class="nav-link px-4 py-2"
              data-toggle="dropdown"
              v-bind="attrs"
              v-on="on"
            >
              <!-- <v-icon size="16">far fa-bell</v-icon> -->
              <img src="/img/icons/notification-bell.svg" alt="" />
              <span
                v-show="notifications.length"
                style="font-size: 10px"
                class="badge badge-warning navbar-badge"
              >
                {{ notifications.length }}
              </span>
            </a>
          </template>
          <div
            class="bg-white rounded"
            style="
              border: 1px solid rgba(0, 0, 0, 0.15);
              min-width: 280px;
              max-width: 300px;
            "
          >
            <v-btn
              :ripple="false"
              text
              dense
              tile
              style="width: 100%"
              class="text-center pa-1 px-0 border-bottom"
            >
              {{ notifications.length }} notifications
            </v-btn>
            <v-list class="pa-0">
              <v-list-item
                v-for="notification in notifications"
                :key="notification.id"
                dense
                class="d-block pa-0"
              >
                <div class="py-2 px-4 d-flex justify-content-between">
                  <div class="d-flex">
                    <v-icon
                      style="width: 21px"
                      size="17"
                      class="mr-2"
                      color="#212529"
                    >
                      mdi-plus
                    </v-icon>
                    <span style="font-size: 16px">
                      {{ notification.text }}
                    </span>
                  </div>
                  <span
                    style="font-size: 14px"
                    class="float-right grey--text text--darken-1 text-sm"
                  >
                  </span>
                </div>
              </v-list-item>
              <v-divider class="my-1" style="color: #e9ecef"></v-divider>
            </v-list>
            <v-btn
              :ripple="false"
              text
              dense
              style="width: 100%"
              class="text-center pa-1 px-0"
              >See All Notifications</v-btn
            >
          </div>
        </v-menu>
      </li>
      <li class="dropdown">
        <div class="px-4 py-2 cursor-pointer" @click="toggleSideCart(true)">
          <!-- <v-icon size="16">fas fa-shopping-cart</v-icon> -->
          <img src="/img/icons/basket-icon.svg" alt="" />

          <span
            v-show="findTotalAmount"
            style="font-size: 10px"
            class="badge badge-success navbar-badge"
          >
            {{ findTotalAmount }}
          </span>
        </div>
      </li>
      <li class="dropdown">
        <v-menu offset-y content-class="menu-shadow">
          <template v-slot:activator="{ on, attrs }">
            <a
              class="nav-link px-4 py-2"
              data-toggle="dropdown"
              v-bind="attrs"
              v-on="on"
              @click.prevent="$router.push({ name: 'profile' })"
            >
              <!-- <v-icon size="16">fas fa-user</v-icon> -->
              <img src="/img/icons/user-icon.svg" alt="" />
            </a>
          </template>
        </v-menu>
      </li>
    </ul>
  </v-app-bar>
  <!-- end:: Header -->
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('cart')
const { mapState: State_notif, mapActions: Actions_notif } =
  createNamespacedHelpers('notifications')

export default {
  name: 'Header',
  data() {
    return {
      scrolled: false,
      testComments: [
        {
          author: 'Brad Diesel',
          body: 'Call me whenever you can...',
          createdon: '4 Hours Ago',
          authorAvatar: '/media/images/user1-128x128.jpg',
        },
        {
          author: 'John Pierce',
          body: 'I got your message bro I got your message broI got your message broI got your message broI got your message broI got your message broI got your message broI got your message bro',
          createdon: '4 Hours Ago',
          authorAvatar: '/media/images/user8-128x128.jpg',
        },
        {
          author: 'Nora Silvester',
          body: 'The subject goes here',
          createdon: '4 Hours Ago',
          authorAvatar: '/media/images/user3-128x128.jpg',
        },
      ],
      testMessages: 4,
      testRequests: 9,
      testReports: 1,
      isLangListDropedDown: false,
      choosenLang: 'ru',
    }
  },
  computed: {
    ...mapState(['cartItems', 'language']),
    ...State_notif(['notifications']),
    testNotificationsCount() {
      return this.testMessages + this.testRequests + this.testReports
    },
    testNotifications() {
      return [
        {
          title: 'new messages',
          value: this.testMessages,
          lastActivity: '3 mins',
          icon: 'fas fa-envelope',
        },
        {
          title: 'friend requests',
          value: this.testRequests,
          lastActivity: '12 hours',
          icon: 'fas fa-users',
        },
        {
          title: 'reports',
          value: this.testReports,
          lastActivity: '2 days',
          icon: 'fas fa-file',
        },
      ]
    },
    computedRef() {
      return this.$refs && this.$refs['app-bar']
    },
    languages() {
      const langs = [
        {
          value: 'en',
          text: 'En',
        },
        {
          value: 'ru',
          text: 'Ru',
        },
        {
          value: 'kg',
          text: 'Kg',
        },
        {
          value: 'kz',
          text: 'Kz',
        },
      ]
      return langs.filter((l) => l !== this.choosenLang)
    },
    findTotalAmount() {
      let sum = this.cartItems.reduce(
        (sum, curr) => (sum += curr.colors.length),
        0
      )
      return sum
    },
  },
  watch: {
    choosenLang(val) {
      this.isLangListDropedDown = false
      this.choosenLang = val
      this.$store.dispatch('language/changeLanguage', val)
      this.$store.dispatch(
        'reyestr/setSelectedGroup',
        val === 'ru' ? 'Все' : 'All'
      )
      this.$i18n.locale = val
      localStorage.setItem('language', JSON.stringify(val))
    },
  },
  methods: {
    ...mapActions(['toggleSideCart']),
    // ...mapActions(['changeLanguage']),
    ...Actions_notif(['addNotification']),
    truncate(string) {
      return string.substr(0, 25) + '...'
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$watch(
        () => this.$refs['app-bar'].classes['v-app-bar--is-scrolled'],
        (val) => {
          this.scrolled = val
        }
      )
    })
  },
}
</script>
<style scoped lang="scss">
.header {
  @media screen and (max-width: 720px) {
    width: 100%;
    left: 0;
  }

  &__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    li {
      margin-right: 15px;

      @media screen and (max-width: 720px) {
        margin-right: 16px;
      }
    }

    li:not(:first-child) {
      @media screen and (max-width: 720px) {
        display: none;
      }
    }

    p {
      font-family: 'Alegreya Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.13em;
      text-transform: uppercase;
      color: #666666;
    }
  }
  .lang__dropdown {
    &-btn {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.13em;
      color: #666666;
      text-transform: uppercase;
      cursor: pointer;
      transition: all ease-in-out 0.3s;
    }
    &-list {
      border-radius: 10px;
      box-shadow: 0px 0px 4px -2px rgb(0 0 0 / 50%);
      position: absolute;
      top: 20px;
      background-color: #ffffff;
      padding: 0;
      margin: 0;
      padding: 5px 15px;
      font-style: normal;
      color: #666666;
      text-transform: uppercase;
      transform: translateX(-50%);
      left: 50%;
    }
  }
}
.menu-shadow {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 18%);
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.navbar-badge {
  font-size: 0.6rem;
  font-weight: 300;
  padding: 2px 4px;
  position: absolute;
  right: 10px;
  top: 2px;
}
.navbar-light .navbar-search-block .form-control-navbar:focus,
.navbar-light
  .navbar-search-block
  .form-control-navbar:focus
  + .input-group-append
  .btn-navbar {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-search-block {
  position: absolute;
  padding: 0 1rem;
  left: 0;
  right: 0;
  z-index: 10;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: initial;
  background-color: #f5f5f5;
}

.navbar-search-block.navbar-search-open {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.navbar-search-block .input-group {
  width: 100%;
}
</style>
